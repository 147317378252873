@charset "UTF-8";
/*CSS Document*/
/* 1. General Text Styles
   2. Title Page Styles
   3. Section Styles
   4. Footer Styles
   5. Social Icon Styles
   6. Column Styles
   7. Container Styles
   8. Navbar Styles
   9. Link Styles */


/*GENERAL TEXT STYLES*/
body{
    margin:0;
    padding:0;
}
body.fourzerofour{
  background-color: #0C1438;
}

h2{
    font-family:'Yantramanav', sans-serif;
    font-size: 32px;
    margin: 0px;
    line-height: 1.5em;
    padding: 20px 20px 10px 20px;
    color: #FFFFFF;
}
h3{
    font-family:'Yantramanav', sans-serif;
    font-size: 26px;
    margin: 0px;
    line-height: 1.5em;
    color: #ffffff;
    padding: 20px 20px 20px 20px;
}
p{
    font-family:'Yantramanav', sans-serif;
    font-weight: 300;
    font-size: 1.5em;
    margin: 0px;
    line-height: 1.5em;
    color: #FFFFFF;
    padding: 10px 10px 20px 20px;
}
li{
    font-family:'Yantramanav', sans-serif;
    font-weight: 300;
    font-size: 1.5em;
    color: #FFFFFF;
}

/*TITLE PAGE STYLES*/
#title-page{
  padding-top: 200px;
  color: #fff;
  text-align: center;
  }
#title-page h1 {
  display: inline;
  font-size: 5em;
  text-shadow: 4px 4px #000;
  font-family: 'Play', sans-serif;
  font-weight: bold;
  letter-spacing: 3px;
  margin:0px;
  color: #fff;
   }
#title-page h2 {
  font-size: 1.9em;
  margin: 0 40px;
  text-shadow: 2px 2px #000;
  font-family:'Yantramanav', sans-serif;
  margin: 0px;
  color: #ffffff;
}

/*SECTION STYLES*/
#splash{
    height: 775px;
    background: url(/images/park1.png) no-repeat center top fixed;
   	background-size: cover;
}
#overview{
    background-color: #0C1438;
}
#about{
    background: url(/images/chairs1.png) no-repeat center top fixed;
    background-size: cover;
}
#abouttext{
  color: #ffffff;
  background-color: rgba(0, 0, 0,.5);
}
.about, h3{
  text-align: center;
}
.about, img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #0C1438;
}
#coaching{
    background-color: #0C1438;
}
#testimonials{
  background: url(/images/grad1.png) no-repeat center top fixed;
  background-size: cover;
}
#testtext{
  color: #ffffff;
  background-color: rgba(0, 0, 0,.5);
}
#contact{
    background-color: #0C1438;
}

/*FOOTER STYLES*/
#footer{
	width: 100%;
	height:60px;
	background-color: #0C1438;
  z-index: 2;
}
a#cc{
  position: relative;
  top: 5px;
  font-family:'Yantramanav', sans-serif;
  margin: 0px;
  line-height: 1.5em;
  color: #ffffff;
  font-size: 1em;
  padding-bottom: 10px;
}
div.foot{
  position: relative;
  top: 15px;

}
#creativecommons{
  width: 1200px;
  height: 60px;
  margin: 0 auto;
}

/*COLUMN STYLES*/
.left{
  float:left;
  width: 50%;
  position: relative;
  bottom: 15px;
}
.right{
  float: right;
  width: 50%;
  position: relative;
  bottom: 15px;
}
#contact div.left, #contact div.right{
  width: 33%;
}

/*CONTAINER STYLES*/
.container {
  width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}
.container2{
  width: 1410px;
  margin: 0 auto;
  overflow: hidden;
}

/*NAVBAR STYLES*/
#navbar{
	position: fixed;
	top: 0;
	width: 100%;
	height:63px;
	background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
}
ul#menu {
	list-style: none;
	padding: 0px;
}
ul#menu li {
	float: left;
	padding-bottom: 20px;
  color: #1E2333;
}
ul#menu img{
  width: 50px;
  height: auto;
  position: relative;
  bottom: 7px;
}
ul#menu li#name{
  padding: 0px 20px 30px 10px;
}
ul#menu li a.button {
	font-size: 20px;
  font-weight: bold;
	text-decoration: none;
	padding: 20px 15px 17px 15px;
}
ul#menu li a.button:hover {
	background-color: rgba(36, 62, 140, .2);
}
ul#menu li a.button:visited {
	color: #1E2333;
}
ul.list {
  margin: 0px 0px 0px 30px;
}
#menu-icon{
  display: none;
  width: 40px;
  height: 40px;
  background: #0C1438 url(/images/menu-icon.png) no-repeat center;
  position: relative;
  bottom: 5px;
  float: right;
}
a:hover#menu-icon {

  background-color: #0e06e5;
	border-radius: 4px 4px 0 0;
}
ul#menu-small li a:hover{
  color: #0e06e5;
}
ul#menu-small{
  display: none;
  position: absolute;
	padding: 10px 20px 10px 20px;
  background: #fff;
	border: 5px solid #0C1438;
  right: 30px;
	top: 35px;
	width: 30%;
	border-radius: 4px 0 4px 4px;
  text-align: center;
}

/*LINK STYLES*/
ul a:link {
	font-weight: bold;
	color: #0C1438;
	text-decoration: none;
}
ul a:link {
	font-weight: bold;
	text-decoration: none;
}
ul a:visited {
	font-weight: bold;
	text-decoration: none;
  color: #0C1438;
}
ul a:hover {
	color: #ffffff;
}