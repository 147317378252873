@charset "UTF-8";
/* CSS Document */

/* window widths smaller than 1200px - the original container size + margins */
@media screen and (max-width: 1410px){
	.container2{
		width: 95%;
	}
	h1{
		font-size: 4em;
	}
}
@media screen and (max-width: 1200px) {

	/* switch from static 960px container to a fluid 90% container at smaller display sizes */
	#creativecommons, .container,.container2 {
		width: 90%;
	}
	ul#menu li a.button {
		padding: 21px 12px;
		font-size: 1em;
	}
	ul#menu li#name, ul#menu li.link{
		position: relative;
		top: 5px;
	}
	.left{
		width: 100%;
	}
	.right{
		float: left;
		width: 100%;
	}
	#title-page h1{
		font-size: 3.5em;
	}
	#title-page h2{
		font-size: 1.5em;
	}
	p,li{
		font-size: 1em;
	}
}
@media screen and (max-width:1060px){
	ul#menu li a.button{
		font-size: .8em;
	}
	li#name{
		font-size: .9em;
	}

}
@media screen and (max-width: 948px){
	#menu-icon{
		display: inline-block;
	}
	ul#menu li.link{
		display: none;
	}
	#menu-icon:hover + ul#menu-small{
		display: block;
	}
	#menu-icon:active + ul#menu-small{
		display:block;
	}
	#menu-icon:focus + ul#menu-small{
		display: none;
	}
	ul#menu-small:hover{
		display:block;
	}
	ul#menu li img{
		width: 35px;
		height: auto;
		position: relative;
		top: -2px;

	}
}
@media screen and (max-width:800px){
	/*change splash image to a more mobile-friendly one on smaller displays*/

	ul#menu li a.button {
		padding: 20px 40px;
		font-size: .8em;
	}
  #title-page h1{
		font-size: 3em;
	}
	#title-page h2{
		font-size: 1.2em;
	}

@media screen and (max-width: 767px) {
	ul#menu li a.button {
		padding: 20px 24px;
		font-size: .6em;
	}
}
@media screen and (max-width: 660px){
	#contact div.left{
		width: 50%;
	}
	#contact div.right{
		width: 90%;
	}
	#contact div.left p, #contact div.right p{
		margin: auto;
	}

}
@media screen and (max-width: 500px){
	#abouttext img{
		width: 80%;
		height: auto;
	}
	ul#menu li#name{
		font-size: .9em;
	}
}
@media screen and (max-width: 480px){
	ul#menu li a.button {
		padding: 12px;
		font-size: .5em;
	}
}
@media screen and (max-width: 463px){
	#contact div.left{
		width: 90%;
	}
}
@media screen and (max-width: 414px){
	#footer{
		height: 100px;
	}
	.fa{
		float: left;
	}
	a#cc{
		display: block;
	}
}
@media screen and (max-width: 350px){
	ul#menu li a.button {
		padding: 8px;
		font-size: .5em;
	}
	ul#menu li#name{
		font-size: .8em;
	}
	}
}
